import store from '@/state/store';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login.vue'),
        meta: {
            title: 'Login',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/account/register.vue'),
        meta: {
            title: 'Register',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/account/forgot-password.vue'),
        meta: {
            title: 'Forgot Password',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/',
        name: 'default',
        meta: {
            title: 'Dashboard',
            authRequired: true,
            appRoles: ['ROLE_CONTACT', 'ROLE_ADMIN', 'ROLE_USER'],
        },
        component: () => import('../views/dashboard/index.vue'),
    },
    //   {
    //     path: "/logout",
    //     name: "logout",
    //     meta: {
    //       title: "Logout", authRequired: true,
    //       beforeResolve(routeTo, routeFrom, next) {
    //         localStorage.clear();
    //         sessionStorage.clear();
    //         next();
    //       },
    //     },
    //     component: () => import("../views/auth/logout/basic")
    //   },
    {
        path: '/formulieren/:companyFormId/:companyName/:formName',
        name: 'Vragenlijst',
        meta: { title: 'Vragenlijst' },
        component: () => import('../views/forms/index'),
    },
    {
        path: '/users/list',
        name: 'Gebruikers lijst',
        meta: { title: 'Gebruikers lijst', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/users/list'),
    },
    {
        path: '/users/detail/:clientId',
        name: 'Gebruiker detail',
        meta: { title: 'Gebruiker detail', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/users/detail'),
    },
    {
        path: '/userMeasuredValues/create/:userId',
        name: 'Meetwaardes toevoegen',
        meta: { title: 'Meetwaardes toevoegen', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/userMeasuredValues/create'),
    },
    {
        path: '/userMeasuredValues/detail/:userMeasuredValuesId',
        name: 'Meetwaardes bewerken',
        meta: { title: 'Meetwaardes bewerken', authRequired: true, appRoles: ['ROLE_USER'] },
        component: () => import('../views/userMeasuredValues/detail'),
    },
    {
        path: '/users/create',
        name: 'Gebruiker toevoegen',
        meta: { title: 'Gebruiker toevoegen', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/users/create'),
    },
    {
        path: '/companies/list',
        name: 'Bedrijf lijst',
        meta: { title: 'Gebruiker lijst', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/companies/list'),
    },
    {
        path: '/companies/detail/:companyId',
        name: 'Bedrijf detail',
        meta: { title: 'Gebruiker detail', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/companies/detail'),
    },
    {
        path: '/companies/create',
        name: 'Bedrijf toevoegen',
        meta: { title: 'Bedrijf toevoegen', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/companies/create'),
    },
    {
        path: '/companyForms/create/:companyId',
        name: 'Bedrijf formulier toevoegen',
        meta: { title: 'Bedrijf formulier toevoegen', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/companyForms/create'),
    },
    {
        path: '/reports/pmo',
        name: 'Rapportage PMO',
        meta: { title: 'Rapportage PMO', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/reports/pmo'),
    },
    {
        path: '/reports/list',
        name: 'Rapportages',
        meta: { title: 'Rapportages', authRequired: true, appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT'] },
        component: () => import('../views/reports/list'),
    },
    {
        path: '/reports/create',
        name: 'Rapportage aanmaken',
        meta: { title: 'Rapportage aanmaken', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/reports/create'),
    },
    {
        path: '/reports/detail/:reportId',
        name: 'Rapportage detail',
        meta: { title: 'Rapportage detail', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/reports/detail'),
    },
    {
        path: '/planners/list',
        name: 'Planner lijst',
        meta: { title: 'Planner', authRequired: true, appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT'] },
        component: () => import('../views/planners/list'),
    },
    {
        path: '/planners/detail/:plannerId',
        name: 'Planner detail',
        meta: { title: 'Planner', authRequired: true, appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT'] },
        component: () => import('../views/planners/detail'),
    },
    {
        path: '/planners/create',
        name: 'Planner create',
        meta: { title: 'Planner', authRequired: true, appRoles: ['ROLE_ADMIN'] },
        component: () => import('../views/planners/create'),
    },
    {
        path: '/not-allowed',
        name: 'Niet toegestaan',
        meta: { title: 'Niet toegestaan', appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT', 'ROLE_USER'] },
        component: () => import('../views/not-allowed/index'),
    },
    {
        path: '/logout',
        name: 'Uitloggen',
        meta: { title: 'Niet toegestaan', appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT', 'ROLE_USER'] },
        component: () => import('../views/account/logout'),
    },
];
